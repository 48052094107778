.tag {
  background-color: var(--blue-200);
  /* user-select: noneを解除できればタグの選択状態管理が楽になるが、Firefoxでタグに囲まれたテキストの選択に不具合が出る */
  user-select: none;
  /* display: inline-block; */
  margin: 0 4px 0 4px;
}

.tag::before {
  /* 下記のようにbeforeにタグテキストを表示し、タグ要素のtextContentには何も入れない方法もあるが、キャレットの移動に不具合が出る */
  /* content: attr(data-content); */
  content: '\00a0';
}

.tag::after {
  content: '\00a0';
}

.tag-begin {
  border-radius: 0 15px 15px 0;
}

.tag-end {
  border-radius: 15px 0 0 15px;
}

.tag-empty {
  border-radius: 5px;
}

.tag-wrapper {
  cursor: pointer;
}

.tag-wrapper.tag-selected > .tag {
  background-color: #accef7;
  padding: 0 4px 0 4px;
  margin: 0;
}

.tag-wrapper.tag-selected::selection {
  background-color: transparent;
}

.newline-marker::before {
  content: '↵';
}

.newline-marker::after {
  content: '';
  display: inline-block;
}

.newline-marker {
  user-select: none;
}

.editor-cell {
  text-align: left;
  vertical-align: top;
  border-collapse: collapse;
  border: 1px solid var(--surface-200);
  white-space: break-spaces;
  overflow-wrap: break-word;
  padding: 4px;
  caret-color: red;
  cursor: text;
  outline: 0px solid transparent;
  line-height: 1.5rem;
}
