.xliff-editor-content,
.xliff-editor-root,
.xliff-editor-splitter-wrapper {
  height: 100%;
  width: 100%;
}

.xliff-editor-root {
  padding-bottom: 1rem;
  position: relative;
  overflow-y: hidden;
}

.xliff-editor-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.xliff-editor-splitter-wrapper {
  /* 67pxは.xliff-editor-headerの高さ  */
  padding-top: 67px;
  margin-top: -67px;
}

.p-confirm-dialog-message {
  white-space: pre-line;
}

.p-splitter .p-splitter-gutter {
  background-color: var(--surface-200);
}

.menubar-base {
  background-color: var(--surface-100);
}

.panel-base {
  background-color: var(--surface-50);
}
