.cat-metadata {
  padding: 5px;
}

.cat-metadata,
.cat-metadata dl {
  width: 100%;
  word-wrap: break-word;
}

.cat-metadata dl {
  display: table;
  table-layout: fixed;
}

.cat-metadata dt {
  margin-right: 5px;
  margin-top: 5px;
  font-weight: bold;
  float: left;
}

.cat-metadata dd {
  margin-top: 5px;
}
