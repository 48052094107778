.status-bar {
  height: 1.5rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.8rem;
}

.status-bar-item {
  margin-left: 15px;
  /* position=absolute内の要素を上下中央にする
    https://stackoverflow.com/a/28456704 */
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.status-bar-item-label {
  font-weight: bold;
}
