.cat-panel-toolbar {
  width: fit-content;
  height: 100%;
}

.cat-panel-toolbar-list {
  list-style: none;
  margin: 0;
  padding: 5px;
  height: 100%;
  border: 1px solid var(--surface-200);
}

.cat-panel-toolbar-list li {
  margin-bottom: 1.5rem;
}
