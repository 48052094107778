.concordance-search-panel {
  overflow-x: hidden;
  width: 100%;
  font-size: 0.8rem;
}

.concordance-search-grid-wrapper {
  overflow-y: auto;
}

.concordance-search-form {
  position: sticky;
  top: 0;
  border: 1px solid var(--surface-200);
}

.concordance-search-panel-progress-msg {
  margin: auto;
  text-align: center;
  font-size: 1rem;
}

.concordance-search-panel-progress-msg .p-progress-spinner {
  height: 2rem;
  vertical-align: middle;
  width: 50px;
}

.concordance-search-grid {
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
  cursor: pointer;
}

.concordance-search-grid,
.concordance-search-grid th,
.concordance-search-grid td {
  border-collapse: collapse;
  border: 2px solid var(--surface-200);
  padding: 0.2rem;
}

.concordance-search-no,
.concordance-search-active {
  width: 30px;
}

.concordance-search-active {
  text-align: center;
}

.concordance-search-match-score {
  width: 50px;
  color: white;
  text-align: center;
}

.concordance-search-src-tmx,
.concordance-search-tgt-tmx {
  vertical-align: top;
}

.concordance-search-match-score-perfect {
  background-color: #31bbd4;
}

.concordance-search-match-score-exact {
  background-color: #92d050;
}

.concordance-search-match-score-fuzzy {
  background-color: #f09456;
}

.concordance-search-selected-tm-entry {
  background-color: #f7ebe9;
}

.concordance-search-basic-search {
  padding: 5px 5px 10px 5px;
}

.concordance-search-basic-search .p-field {
  margin-bottom: 5px;
}

.concordance-search-advanced-search {
  padding: 5px 5px 10px 5px;
}

.concordance-search-advanced-search .p-field {
  margin-bottom: 5px;
}

.concordance-search-advanced-search .p-panel-content {
  margin-bottom: 0;
  padding-bottom: 0.5rem;
}

.concordance-search-advanced-search .p-inputnumber {
  height: 33px;
}

.concordance-search-highlighted {
  background-color: palegreen;
}
