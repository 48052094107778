.document-search-panel {
  overflow-x: hidden;
  width: 100%;
  font-size: 0.8rem;
}

.document-search-grid-wrapper {
  overflow-y: auto;
}

.document-search-form {
  position: sticky;
  top: 0;
  border: 1px solid var(--surface-200);
}

.document-search-panel-progress-msg {
  margin: auto;
  text-align: center;
  font-size: 1rem;
}

.document-search-panel-progress-msg .p-progress-spinner {
  height: 2rem;
  vertical-align: middle;
  width: 50px;
}

.document-search-grid {
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
  cursor: pointer;
}

.document-search-grid,
.document-search-grid th,
.document-search-grid td {
  border-collapse: collapse;
  border: 2px solid var(--surface-200);
  padding: 0.2rem;
}

.document-search-no {
  width: 45px;
}

.document-search-src-text,
.document-search-tgt-text {
  vertical-align: top;
}

.document-search-basic-search {
  padding: 5px 5px 10px 5px;
}

.document-search-basic-search .p-field,
.document-search-basic-search .p-field-checkbox {
  margin-bottom: 5px;
}

.document-search-highlighted {
  background-color: palegreen;
}

.document-search-results-count {
  border: 2px solid var(--surface-200);
  padding: 0.3rem 0.7rem;
  font-style: italic;
}

.loading-msg-cell {
  text-align: center;
  font-size: 0.8rem;
  font-style: italic;
  height: 50px;
}

.loading-msg-cell .p-progress-spinner {
  height: 1rem;
  width: 25px;
  vertical-align: middle;
}
