.xliff-grid-wrapper {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.xliff-grid {
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid var(--surface-200);
  font-size: 14px;
  width: 100%;
}

.xliff-grid thead th {
  /* 縦スクロール時に固定する
     https://since-inc.jp/blog/8675 */
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  /* tbody内のセルより手前に表示する */
  z-index: 1;
  background-color: var(--surface-200);
}

.xliff-grid thead th::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
}

.xliff-grid .generic-col {
  border-collapse: collapse;
  border: 1px solid var(--surface-200);
}

.xliff-grid .source-col {
  border-collapse: collapse;
  border: 1px solid var(--surface-200);
}

.xliff-grid .target-col {
  border-collapse: collapse;
  border: 1px solid var(--surface-200);
}

.loading-next-msg-cell,
.loading-prev-msg-cell {
  text-align: center;
  font-size: 1.2rem;
  font-style: italic;
  height: 50px;
}

.loading-next-msg-cell .p-progress-spinner,
.loading-prev-msg-cell .p-progress-spinner {
  height: 2rem;
  width: 50px;
  vertical-align: middle;
}
