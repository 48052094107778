.edit-menu-list .p-submenu-list {
  width: 17rem !important;
}

.view-menu-list .p-submenu-list {
  width: 20rem !important;
}

.navigation-menu-list .p-submenu-list {
  width: 17rem !important;
}

.app-menubar {
  height: 40px;
  font-size: 0.8rem;
}

.app-menubar .p-menubar-end button,
#user-menu {
  font-size: 0.8rem;
}
