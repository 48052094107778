.tm-lookup-panel {
  overflow-x: hidden;
  width: 100%;
  font-size: 0.8rem;
}

.tm-lookup-grid-wrapper {
  overflow-y: auto;
}

.tm-lookup-panel-progress-msg {
  margin: 2rem 0 0 0;
  text-align: center;
  font-size: 1rem;
}

.tm-lookup-panel-progress-msg .p-progress-spinner {
  height: 2rem;
  vertical-align: middle;
  width: 50px;
}

.tm-lookup-grid {
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
  cursor: pointer;
}

.tm-lookup-grid,
.tm-lookup-grid th,
.tm-lookup-grid td {
  border-collapse: collapse;
  border: 2px solid var(--surface-200);
  padding: 0.2rem;
}

.tm-lookup-no {
  width: 30px;
}

.tm-lookup-match-score {
  width: 50px;
  color: white;
  text-align: center;
}

.tm-lookup-src-tmx,
.tm-lookup-tgt-tmx {
  vertical-align: top;
}

.tm-lookup-match-score-perfect {
  background-color: #31bbd4;
}

.tm-lookup-match-score-exact {
  background-color: #92d050;
}

.tm-lookup-match-score-fuzzy {
  background-color: #f09456;
}

.tm-lookup-selected-tm-entry {
  background-color: #f7ebe9;
}

.tm-lookup-diff {
  margin: 5px;
  padding: 5px;
  font-size: 1rem;
  border: 2px solid #9a9a9a;
}

.tm-lookup-diff-added {
  background-color: palegreen;
  text-decoration: underline;
}

.tm-lookup-diff-removed {
  background-color: salmon;
  text-decoration: line-through;
}
