[data-is-locked='true'] {
  background-color: var(--surface-300);
}

tr.active {
  /* primereactのprimary-colorを使用する */
  border: 2px solid var(--primary-color);
}

tr.active td:first-child {
  /* primereactのprimary-colorを使用する */
  background-color: var(--primary-color);
  color: white;
}

.generic-cell {
  border-collapse: collapse;
  border: 1px solid var(--surface-200);
  text-align: center;
  word-wrap: break-word;
}

td .p-field {
  margin: 1rem 0.5rem;
}

td .p-component {
  width: 100%;
}

.communication-inactive {
  transition: background-color 0.5s;
}

.communication-active {
  background-color: #90ee90;
  transition: background-color 0.5s;
}
